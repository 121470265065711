@tailwind base;
@tailwind components;
@tailwind utilities;
/* google font url */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&display=swap');

@layer base{
    body {
        @apply font-[poppins];
    }
    li{
        @apply px-4;
        @apply cursor-pointer
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height:250px;
}

.content-div:hover {
    transition: all;
    transition-duration: 0.5s;
    background-image: linear-gradient(
        to right,
        rgba(243,194,175,0.8),
        rgba(208,144,117, 0.8)
    )!important;
}